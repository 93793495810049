@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Serif&family=Poppins:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');

@font-face {
  
  font-family: 'StrangerThings';
  src: url('./fonts/StrangerThings-Regular.ttf') format('truetype');
  
  
}

@font-face {
  font-family: 'ThereBrat';
  src: url('./fonts/There\ Brat.otf') format('opentype');
  src: url('./fonts/There\ Brat.ttf') format('truetype');
}

